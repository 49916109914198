import React from "react";

const InGrid = ({ children, columns }) => {
  const cols = columns || 12;
  return (
    <div className="container">
      <div className="row">
        <div className={`col-md-${cols}`}>{children}</div>
      </div>
    </div>
  );
};

export default InGrid;
